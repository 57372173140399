<template>
    <div>
      <div class="search_filter_wrap">
            <div class="search_item_wrap">
                <div class="filter_category_box">
                    <el-select id="category" v-model="searchInputOption" class="my_filter select"
                               popper-class="category_select"
                               :popper-append-to-body="false">
                        <el-option :value="'ti'" :label="$t('subject')"></el-option>
                        <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                        <el-option :value="'ni'" :label="$t('organizer')"></el-option>
                    </el-select>
                </div>
                <div class="search_input">
                    <input :placeholder="`${$t('st')}`" v-model="searchInput" @keypress.enter="setFilterFunc(searchInputOption,searchInput)"></input>
                    <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)"><span>{{ $t('search') }}</span><img
                        src="@/assets/image/main/search_btn.png"></button>
                </div>
                <div class="list_type">
                    <button class="list_type_btn listType"
                            v-show=" listChange === 'thumbnail'"
                            @click="listTypeChange('list')"></button>
                    <button class="list_type_btn "
                            v-show=" listChange === 'list'"
                            @click="listTypeChange('thumbnail')"></button>
                </div>
                <div class="end_filter">
                    <!--            <button class="filter_btn one" @click="openModal('filter-modal')"></button>-->
                    <el-popover
                        popper-class="top_500"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,
                        }">
                        <el-button slot="reference" class="filter_btn two"
                                   @click="changeCheck('filterEndCheck')"></el-button>
                        <div class="popup_tail"></div>
                        <div class="popup_bg">
                            <div class="popup_inner">
                                <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                                <div class="inner_middle">
<!--                                    <div class="sub_title">{{ $t('category') }}</div>-->
<!--                                    <div class="status_wrap">-->
<!--                                      <el-checkbox-group v-model="filterCategory"-->
<!--                                                         @change="setFilterFunc('icid', filterCategory)">-->
<!--                                        <el-checkbox :label="2" >{{ $t('art') }}</el-checkbox>-->
<!--                                        <el-checkbox :label="9" >{{ $t('collections') }}</el-checkbox>-->
<!--                                        <el-checkbox :label="3" >{{ $t('picture') }}</el-checkbox>-->
<!--                                        <el-checkbox :label="5" >{{ $t('music') }}</el-checkbox>-->
<!--                                        <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
<!--                                      </el-checkbox-group>-->

<!--                                    </div>-->
                                    <div class="sub_title">{{ $t('status') }}</div>
                                    <div class="edition_wrap">
                                        <el-checkbox-group v-model="filterStatus"
                                                           @change="setFilterFunc('st', filterStatus)">
                                        <el-checkbox :label="0" >{{ $t('scheduled_to_proceed') }}</el-checkbox>
                                        <el-checkbox :label="1" >{{ $t('in_process2') }}</el-checkbox>
                                        <el-checkbox :label="2">{{ $t('under_review') }}</el-checkbox>
                                        <el-checkbox :label="3">{{ $t('p_completed') }}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div class="sub_title">{{ $t('prize') }}</div>
                                    <div class="price_wrap">
                                        <el-input v-model="filterPrice[0]" class="el_price_input el_input_num"
                                                  type="text" @keypress.native.enter="applyFilter()"
                                                  oninput="value = value.replace(/[^0-9.]/g,'')"
                                                  />
                                      <span> Mg</span>
                                      <span class="first_input_step">~</span>
                                        <el-input v-model="filterPrice[1]" class="el_price_input el_input_num"
                                                  type="text" @keypress.native.enter="applyFilter()"
                                                  oninput="value = value.replace(/[^0-9.]/g,'')"
                                                  />
                                        <span> Mg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper_bottom" @click="applyFilter()">
                            <button >{{ $t('apply2') }}</button>
                        </div>
                    </el-popover>
                </div>
                <div class="filter_sort_box">
                    <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                               popper-class="category_select"
                               :popper-append-to-body="false"
                               @change="setSortFunc(sortOption)">
                      <el-option :value="'created_at'" :label="`${$t('b_update')}`"></el-option>
                      <el-option :value="'c_hit'" :label="`${$t('b_enter_m')}`"></el-option>
                      <el-option :value="'likeCnt'" :label="`${$t('b_like')}`"></el-option>
                      <el-option :value="'c_prize'" :label="`${$t('priceDesc1')}`"></el-option>
                      <el-option :value="'c_prize_asc'" :label="`${$t('priceAsc1')}`"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import competitionUtil from "@/components/competition/competitionUtil";
const {filterConfig} = competitionUtil
export default {
    name: "CompetitionFilterOtherLayout",
    mixins: [],
    components: {},
    inject: ['setFilter','setSort'],

    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            listChange: 'list',
            searchInputOption: 'ti',
            searchInput: '',
            sortOption: 'created_at',
            filterStatus:[],
            filterCategory:[],
            filterPrice:[],
            minPrice:'',
            maxPrice:''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        openModal(name) {
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        listTypeChange(type) {
            this.listChange = type;
            EventBus.$emit('changeListType',type);
        },
        setSortFunc(key) {
            let order = 'desc'
            if(key.includes('_asc')){
                let idx = key.indexOf('_asc')
                key = key.substring(0,idx)
                order = 'asc'
            }
            this.setSort(key,order)
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        applyFilter(){
           /* this.filterPrice[0] = this.minPrice ? this.minPrice : ''
            this.filterPrice[1] = this.maxPrice  ? this.maxPrice : ''*/
            this.setFilter('pr', this.filterPrice);

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
